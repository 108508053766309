.modal-card {
    padding: 12px 24px 32px 24px;
    background-color: var(--white);
    border-radius: 24px;
    width: 580px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}


.modal-card-close {
    align-self: flex-end;
    color: var(--primary)
}