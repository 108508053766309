.tabbed-menu {
    background-color: var(--primary-dark);
}

.tabbed-menu-tab {
    all: unset;
    cursor: pointer;
    height: 100%;
    background-color: transparent;
    color: var(--white);
    padding: 7px 14px 7px 22px;
}

.tabbed-menu-tab-active {
    background-color: var(--white);
    border-radius: 10px 10px 0 0;
    color: var(--primary-dark)
}