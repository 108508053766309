.usuario-form-container {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}

.usuario-buttons-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.usuario-container span {
    color: #FF8E24;
}

.usuario-form-container .custom-select {
    position: relative;
    border-radius: 10px;
    border: 1px solid #FF8E24;
    height: 56px;
    display: flex;
    align-items: center;
}

.usuario-form-container .custom-select p{
    top: 18px
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #FF8E24 !important;
    border-radius: 10px !important;
}

.MuiTextField-root {
    margin-top: 9px !important;
}

.usuario-container {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.usuarios-tabla-container {
    width: 100%;
    flex-grow: 1;
}

.buttons-container {
    display: flex;
    justify-content: center;
    gap: 25px
}