.carga-masiva {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 38px 65px;
    gap: 40px;
}

.carga-masiva-main {
    display: flex;
    gap: 80px;
}

.carga-masiva-main-pasos h3 {
    font-size: 18px;
    margin-bottom: 40px;
}

.carga-masiva-main-pasos ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.carga-masiva-actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
}