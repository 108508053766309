.custom-select {
    position: relative;
}

.custom-select p {
    position: absolute;
    top: 10px;
    left: 14px;
    color: var(--text-light);
    font-size: 16px;
}