.header {
    width: 100%;
    background-color: var(--primary-light);
    height: 80px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-user {
    display: flex;
    align-items: center;
    color: var(--white);
    gap: 16px;
}

.user-icon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}