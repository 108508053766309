.custom-button {
    text-transform: none !important;
    all: unset;
    border-radius: 10px !important;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700 !important;
    cursor: pointer;
    box-shadow: none !important;
}

.custom-button-icon {
    width: 25px;
    height: 25px;
}

.custom-button-icon svg{
    width: 100%;
    height: 100%;
}

.custom-button-primary {
    background-color: var(--primary-light) !important;
    border: 0;
    color: var(--white) !important;
}

.custom-button-secondary {
    background-color: var(--white) !important;
    border: 1px solid var(--primary-light) !important;
    color: var(--primary-light) !important
}

.custom-button-icon-left {
    order: 0;
}

.custom-button-icon-right {
    order: 1;
}