.menu-container {
    
}

.configuracion-container {
    display: flex;
    height: calc(100vh - 120px);
    padding: 32px 38px;
}

.item {
    font-size: 16px;
    font-weight: 400;
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px
}

.item-active {
    font-weight: 700;
}

.menu {
    color: white;
    display: flex;
    min-width: 300px;
    padding: 31px 24px 31px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    border-radius: 24px;
    background: #B35D0C;
}

.MuiOutlinedInput-notchedOutline{
    border-color: #FF8E24 !important;
 }