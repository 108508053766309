.progress-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 8px;
}

.progress-bar .MuiLinearProgress-root {
    background: var(--Primary-Light-op, rgba(255, 155, 61, 0.60));
}

.progress-bar .MuiLinearProgress-bar {
    background: var(--Primary-Light-op, rgba(255, 155, 61, 0.60));
}