.navbar {
    width: 100%;
    background-color: var(--primary);
    height: 40px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-items {
    display: flex;
    align-items: center;
    gap: 27px;
    font-weight: 600;
}

.navbar-item,.navbar-item-configuracion, .navbar-item-configuracion-last, .navbar-item-last {
    position: relative;
    color: var(--white);
    text-decoration: none;
}

.navbar-item::after {
    content: "|";
    position: absolute;
    right: -17px;
}

.navbar-item-active, .navbar-item-configuracion-active {
    font-weight: 900;
}

.navbar-configuracion {
    all: unset;
    color: var(--white);
    font-weight: 900;
    cursor: pointer;
}