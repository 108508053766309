.changeContainer {
    width: 100%;
    background-color: var(--primary-light);
    display:flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif;
    color: #161616;
}

.logo {
    padding: 25px 0 15px 0;
}

.changeBox {
    background-color: rgba(255, 255, 255, 0.6);
    width: 528px;
    height: 450px;
    text-align: center;

    border-radius: 24px;
    gap: 10px;
}

.changeTitle {
    font-weight: 300;
    font-size: 38px;
    line-height: 38.19px;
    color: #161616;
}
