.search-input {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 14px;
    height: 40px;
}

.search-input input {
  font-family: 'Montserrat', sans-serif !important;

    border: 0;
    color: var(--text-light);
    font-size: 16px;
    font-weight: 300;
}

.search-input input:focus {
    border: 0;
    outline: none;
}

.search-input input::placeholder {
    color: var(--text-light);
    font-size: 16px;
    opacity: 1;
}

.search-input-icon {
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);
}