@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat-VariableFont_wght.ttf) format(truetype);
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-light: #FF9B3D;
  --primary: #D0741E;
  --primary-dark: #B35D0C;
  --text-light: #424242;
  --white: #ffffff;
  --omitir: #440707;
  --error: #E52328;
  --success: #009928;
}

.sendActionButton {
  border-radius: 10px !important;
  background-color: var(--primary-light) !important;
  box-shadow: none !important;
  font-family: 'Montserrat', sans-serif !important;
  text-transform: none !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  width: 271px;
  height: 38px !important;
}