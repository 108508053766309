.main {
    display: flex;
    padding: 37px 19px;
    gap: 24px;
}

.main-toolbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    min-width: 211px;
    width: 211px;
}

.main-toolbar-filters {
    background-color: #FFFEFE;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid var(--primary-light);
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    overflow: hidden;
}

.main-toolbar-filters-button {
    padding: 33px 16px;
}

.main-toolbar-header {
    background-color: var(--primary-light);
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-weight: 500;
    font-size: 20px;
}

.datagrid-envio {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.datagrid-envio-actions {
    display: flex;
    gap: 24px;
    justify-content: center;
}

.modal-ver-container {
    max-height: 70vh;
    overflow: auto;
}

.modal-ver-container p {
    word-break: break-word;
}

.modal-ver-container h2 {
    margin-bottom: 32px;  
    align-self: center;  
}

.modal-data-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
}
