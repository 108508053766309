.loginContainer {
    width: 100%;
    background-color: var(--primary-light);
    display:flex !important;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif;
    color: #161616;
}

.inputs-container input {
    background: content-box; 
}

.logo {
    padding: 25px 0 15px 0;
}

.errorMsg {
    color: red;
    font-size: 13px;
    padding-top: 4px;
}

.loginBox {
    background-color: rgba(255, 255, 255, 0.6);
    width: 528px;
    height: 527px;
    text-align: center;

    border-radius: 24px;
    gap: 10px;
}

.loginTitle {
    font-weight: 300;
    font-size: 38px;
    line-height: 38.19px;
    color: #161616;
}

.remember {
    text-align: left;
    padding-left: 130px;
    font-family: 'Montserrat', sans-serif !important;

    span {
        font-family: 'Montserrat', sans-serif;
        color: rgba(66, 66, 66, 1);
        font-size: 14px;
    }
}

.forgetPassword a {
    font-family: 'Montserrat', sans-serif !important;
    color: #161616;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
}